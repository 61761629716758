
import { defineComponent, computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { getCurretParentBu,getAllParentBu } from '@/API/salesReporting/claimCheckingReport';
export default defineComponent({
  emits: ['init-value'],
  props: {
    // 添加一个参数 isCurrent，类型为布尔值，默认为 false
    isCurrent: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    // const { state } = useStore();
    //     const buList = computed(() =>
    //         state.user.buList.filter((bu: any) => {
    //             return bu.parentId === null;
    //         })
    //     );
    const buList = ref<any[]>([]);

    const dogetDefault = () => {
      const mbIndex = buList.value.findIndex((i: any) => {
        return i.nameEn === 'MBPC';
      });
      const initIndex = mbIndex === -1 ? 0 : mbIndex;
      emit('init-value', buList.value[initIndex].nameEn);
    };

    const getBuList = async () => {
        const apiFunction = props.isCurrent ? getCurretParentBu :getAllParentBu
        const res = await apiFunction();
        buList.value = res;
        buList.value = buList.value.filter((bu: any) => {
          return bu.parentId === null;
        });
        buList.value = buList.value.filter((bu: any) => {
          return bu.nameEn !== 'Companycar';
        });
        dogetDefault();
    };
    onMounted(getBuList);
    return {
      buList
    };
  }
});
